import Contact from "../Components/Contact/Contact"



const ContactPage= () =>{
   return(
       
           <Contact />
       
   ) 
}

export default ContactPage